import React from 'react';
import './SayHi.css';
import hello from '../assests/sayhi.png'; // Update the path to your image
import icon1 from '../assests/linkedin.png'; // Update the path to your icons
import icon2 from '../assests/instagram.png';
import icon3 from '../assests/whatsapp.png';
import buttonIcon from '../assests/email.png';

const SayHi = () => {
  return (
    <div className="say-hi-section">
      <div className="flex-container">
        <div className="right-flex">
          <img src={hello} alt="Say Hi " className="say-hi-image" />
        </div>
        <div className="left-flex">
          <div className="text-section">
            <p>Let's connect and create something magical together. Whether you have a project in mind or just want to say hello, I'm always open to new possibilities.</p>
          </div>
          <div className="button-section">
            <a href="mailto:prabathr651@gmail.com" className="contact-button">
              <img src={buttonIcon} alt="Button Icon" className="button-icon" />
              Say hi!
            </a>
            <div className="icons-section">
              <a href="https://www.linkedin.com/in/prabhath-rathnayaka-01203b217/"><img src={icon1} alt="Icon 1" /></a>
              <a href="https://www.instagram.com/prabhath_rathnayka/"><img src={icon2} alt="Icon 2" /></a>
              <a href=" https://www.whatsapp.com/business/"><img src={icon3} alt="Icon 3" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SayHi;
