import React from 'react';
import './ComingSoonLabel.css';

const ComingSoonLabel = () => {
  return (
    <div className="coming-soon-label">COMING SOON</div>
  );
};

export default ComingSoonLabel;
