import React from 'react';
import './Card.css';
import ComingSoonLabel from './ComingSoonLabel'; // Import the ComingSoonLabel component

const Card = ({ title, subtitle, imageSrc, iconSrc, link, comingSoon }) => {
  return (
    <a href={link} className="card-link">
      <div className="card">
        <div className="card-image-wrapper">
          {comingSoon && <ComingSoonLabel />} {/* Use the ComingSoonLabel component */}
          <img src={imageSrc} alt={title} className="card-image" />
        </div>
        <div className="card-text-section">
          <div className="text-column">
            <p>{subtitle}</p>
            <h3>{title}</h3>
          </div>
          <div className="icon-column">
            <img src={iconSrc} alt="Icon" />
          </div>
        </div>
      </div>
    </a>
  );
};

export default Card;
