import React, { useState } from 'react';
import './Work.css';
import Card from '../components/Card.jsx';
import Title from '../components/Title.jsx'; // Import Title component


// Import images
import uxB from '../assests/uxB.png';
import uxc from '../assests/uxc.png';
import codingB from '../assests/codingB.png';
import codingc from '../assests/codingc.png';
import brandingB from '../assests/brandingB.png';
import brandingc from '../assests/brandingc.png';
// Import images for the cards
import cardImage1 from '../assests/ux1.png';
import cardImage2 from '../assests/ux2.png';
import cardImage3 from '../assests/ux3.png';
import cardImage4 from '../assests/ux4.png';
import cardImage5 from '../assests/rn01.png';
import icon1 from '../assests/figma.png';
import icon2 from '../assests/reacti.png';
import cardImage6 from '../assests/coding2.png';
import cardImage7 from '../assests/coding3.png';
import icon3 from '../assests/swirti.png';
import cardImage9 from '../assests/br1.png';
import cardImage10 from '../assests/br2.png';
import cardImage11 from '../assests/br3.png';
import cardImage12 from '../assests/br4.png';
import icon4 from '../assests/psi.png';
import cardImage13 from '../assests/ux5.png';
import cardImage14 from '../assests/ux6.png';

const RecentWorks = () => {
  const [activeFilter, setActiveFilter] = useState('option1');

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  const cardsData = {
    option1: [
      { title: 'Mobile App to Solve Lost Item Issue', subtitle: 'UX Case Study', imageSrc: cardImage2, iconSrc: icon1, link: 'https://prabhathux.hashnode.dev/ux-case-study-a-mobile-app-to-solve-lost-item-issues-for-university-students' },
      { title: 'Mobile App for Skin Welness', subtitle: 'UI Design', imageSrc: cardImage3, iconSrc: icon1, link: 'https://www.behance.net/gallery/203991459/Skin-Wellness-Mobile-App' },
      { title: 'Web Platfoem for Counsellor Booking', subtitle: 'UX Case Study', imageSrc: cardImage1, iconSrc: icon1, link: '#', comingSoon: true  },
      { title: 'Mobile App for Covid News', subtitle: 'UI Design', imageSrc: cardImage4, iconSrc: icon1, link: 'https://www.behance.net/gallery/203993883/COVID-Updates-Mobile-App' },
      { title: 'Mobile App for Movie Ticket Booking', subtitle: 'UX Case Study', imageSrc: cardImage13, iconSrc: icon1, link: 'https://www.behance.net/gallery/149338891/Movie-Ticket-Booking-App-UX-Case-Study' },
      { title: 'E Commerce Platform', subtitle: 'UI Design', imageSrc: cardImage14, iconSrc: icon1, link: 'https://www.behance.net/gallery/173819483/E-commerce-site-UI-Electronic-appliance-store' }
    ],
    option2: [
      { title: 'App for Signature Identification', subtitle: 'Mobile app with React Native', imageSrc: cardImage5, iconSrc: icon2, link: 'https://github.com/PrabhathR-99/DetectApp' },
      { title: 'My Portfolio', subtitle: 'Figma | React & CSS', imageSrc: cardImage6, iconSrc: icon2, link: 'https://github.com/PrabhathR-99/prabhathux' },
      { title: 'Pet Adoption Platform', subtitle: 'Figma | React & CSS', imageSrc: cardImage7, iconSrc: icon2, link: 'https://github.com/PrabhathR-99/Group_09-Save_Peanut-Pet_adoption_web_app' },
      { title: 'Covid News App', subtitle: 'To learn SwiftUI', imageSrc: cardImage4, iconSrc: icon3, link: '#' }
    ],
    option3: [
      { title: 'Brand Identity for e-commerce store', subtitle: 'Brand identity design', imageSrc: cardImage9, iconSrc: icon4, link: 'https://www.behance.net/gallery/203956585/E-commerce-store-branding' },
      { title: 'E-Sport event branding', subtitle: 'Social media post series', imageSrc: cardImage10, iconSrc: icon4, link: 'https://www.behance.net/gallery/175410167/Social-Media-Posts-E-Sport-tournament' },
      { title: 'Music event branding', subtitle: 'Social media post series with the logo', imageSrc: cardImage11, iconSrc: icon4, link: 'https://www.behance.net/gallery/188846853/Social-media-post-series-Music-event' },
      { title: 'Food & Resturant', subtitle: 'Social media post series', imageSrc: cardImage12, iconSrc: icon4, link: 'https://www.behance.net/gallery/175271659/Social-Media-Posts-Food-and-Restaurant' }
    ]
  };

  const renderCards = (cards) => {
    return cards.map((card, index) => (
      <Card
        key={index}
        title={card.title}
        subtitle={card.subtitle}
        imageSrc={card.imageSrc}
        iconSrc={card.iconSrc}
        link={card.link}
        comingSoon={card.comingSoon}
      />
    ));
  };

  return (
    <div id="Recent-works" className="Recent-works">
      <Title
        mainTitle={<>MY WORKS</>}
        subtitle="Explore my latest creations across the multiverse"
      />
      <div className="filter-buttons">
        <button
          onClick={() => handleFilterClick('option1')}
          className={activeFilter === 'option1' ? 'active' : ''}
          data-filter="option1"
        >
          <img
            src={activeFilter === 'option1' ? uxc : uxB}
            alt="UX & UI"
            className="filter-icon"
          />
          UX & UI
        </button>
        <button
          onClick={() => handleFilterClick('option2')}
          className={activeFilter === 'option2' ? 'active' : ''}
          data-filter="option2"
        >
          <img
            src={activeFilter === 'option2' ? codingc : codingB}
            alt="Coding"
            className="filter-icon"
          />
          CODING
        </button>
        <button
          onClick={() => handleFilterClick('option3')}
          className={activeFilter === 'option3' ? 'active' : ''}
          data-filter="option3"
        >
          <img
            src={activeFilter === 'option3' ? brandingc : brandingB}
            alt="Branding"
            className="filter-icon"
          />
          BRANDING
        </button>
      </div>
      <div className="wcards-container">
        {renderCards(cardsData[activeFilter])}
      </div>
    </div>
  );
};

export default RecentWorks;
