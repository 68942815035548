// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Work from './pages/Work';
import About from './pages/About';
import MyStory from './pages/MyStory';
import Blog from './pages/Blog';
import NavBar from './components/NavBar';
import Footer from './components/footer';
import './App.css';

import Contact from './pages/Contact';


const App = () => {
  return (
    <Router>

      <NavBar />
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} /> 
          <Route path="/work" element={<Work />} />
          <Route path="/about" element={<About />} />
          <Route path="/mystory" element={<MyStory />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact/>}/>
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
