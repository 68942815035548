import React from 'react';
import './TimeLine.css'; // Ensure correct path to your CSS

// Import tech stack images
import revoluxImage from '../assests/revolux.jpeg';
import aiesecImage from '../assests/aiesec.png';
import morauxImage from '../assests/moraux.jpg';
import ixImage from '../assests/ix.jpeg';
import fortnightImage from '../assests/fortnight.jpeg';
import pearlhackImage from '../assests/pearlhack.jpeg';
import ieeeImage from '../assests/ieee.jpeg';
import codesprintImage from '../assests/codesprint.jpeg';

const milestones = [
    {
        date: 'Jun 2024',
        image: codesprintImage,
        title: 'Codesprint 8  - Finalist',
        paragraphs: [
          'Being in the final 10 of Codesprint 8, organized by the Informatics Institute of Technology (IIT Campus), was a significant experience. This high-scale competition broadened our view of the industry.  ',
          ' I handled the UX & UI for our mobile app, SkinVisions. The workshops of the event taught us how to align business with our product. I am grateful to the organizers and mentors for their invaluable knowledge and opportunities.'
        ]
      },
    {
        date: 'Aug 2023 - Present',
        image: ieeeImage,
        title: 'Treasurer - IEEE Student Branch',
        paragraphs: [
          'Becoming the treasurer of the IEEE Student Branch at SUSL was a unique experience for me. Before this role, I had contributed to several design projects with IEEE. Stepping into the treasurer position was outside my comfort zone, but it was a valuable experience from a different field.',
          ' We organized several events, and I had to play a different role than usual, which broadened my skills and perspective.'
        ]
      },
    {
        date: 'Oct 2023 - Feb 2024',
        image: pearlhackImage,
        title: 'Perlhack 2.0 - Contributing as a Designer',
        paragraphs: [
          'Contributing as a UX/UI and graphic designer for Pearl Hack 2.0, organized by the IEEE WIE Student Branch Affinity Group at Sabaragamuwa University of Sri Lanka, was a proud moment. I streamlined the participant experience and created impactful visuals for this women-focused tech empowerment event.  ',
          ' Supporting diversity and innovation in technology through meaningful design contributions was incredibly rewarding.'
        ]
      },
    {
        date: 'Sep 2023',
        image: fortnightImage,
        title: '30th Fortnight Meetup – Sharing Knowledge',
        paragraphs: [
          'A major turning point in my journey was being a resource person at the 30th Fortnight Meetup organized by the Society of Computer Sciences (SOCS) of Sabaragamuwa University of Sri Lanka. During this event, I had the chance to conduct a session for our faculty students on "The Basics of UX & UI." ',
          'There I spoke about the importance of UX & UI, how to start in UX, and some basics of visual design. I am really greatful for this opportunity, and it was  great a experience for me '
        ]
      },
    {
        date: 'Aug 2022',
        image: ixImage,
        title: 'IX23 - Finalist',
        paragraphs: [
          'Being finalists of IX23, a well-organized UX & UI designation by the Informatics Institute of Technology (IIT Campus). Our team made it to the final 10 and faced a unique challenge: designing for Extended Reality. ',
          ' It was an amazing experience, and as a team, we did great. This is a moment in my journey that I will never forget. '
        ]
      },
    {
        date: 'Jul 2023',
        image: morauxImage,
        title: 'Mora UXplore 1.0 - Finalist',
        paragraphs: [
          'Being finalists of Mora UXplore, a UX & UI designation organized by The IEEE University of Moratuwa Student Branch, was another exciting chapter in my journey. ',
          ' Our team made it to the final 10, and the experience was invaluable for us. '
        ]
      },
    {
        date: 'Jun 2021 - Jan 2022',
        image: aiesecImage,
        title: 'AIESEC in SUSL – Team Member to IGVP',
        paragraphs: [
          'My journey with AIESEC in SUSL began as an OGT team member and eventually as IGVP. I was grateful to be an organizing committee member for several events, including "Overhaul 3.0," "Qualifier 2.0," and "Talent Path 1.0."',
          ' Volunteering with AIESEC and working as part of a dedicated team was an incredibly rewarding experience. '
        ]
      },
    {
        date: 'Aug 2022',
        image: revoluxImage,
        title: 'RevolUX 2.0 - Finalist',
        paragraphs: [
          'This is where I started my UX journey: at RevolUX 2.0, one of the biggest UX & UI designations in Sri Lanka, organized by the University of Colombo School of Computing.',
          ' During my first year, my team and I, driven by passion, made it to the top 10. I am truly grateful to my amazing team members who supported me from the very beginning. '
        ]
      },
    // Add more milestones as needed
];

const Timeline = () => {
    return (
        <div className="timeline">
            {milestones.map((milestone, index) => (
                <div className="timeline-item" key={index}>
                    <div className="timeline-date">
                        {milestone.date}
                    </div>
                    <div className="timeline-card">
                        <img src={milestone.image} alt={milestone.title} className="timeline-image" />
                        <div className="timeline-content">
                            <h3 className="timeline-title">{milestone.title}</h3>
                            {milestone.paragraphs.map((paragraph, i) => (
                                <p key={i} className="timeline-paragraph">{paragraph}</p>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Timeline;
