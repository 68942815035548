// src/pages/Home.jsx
import React from 'react';
import './Home.css';

import heroImage from '../assests/hero.png';
import GradientButton from '../components/GradientButton';
import RecentWorks from '../components/RecentWorks';
import Title from '../components/Title'; 
import SayHi from '../components/SayHi';

const Home = () => {
  return (
    <div className="container mx-auto">
      <img src={heroImage} alt="Hero" className="hero-image" /> 
      <h1 className="text-4xl font-bold">Prabhath Rathnayaka</h1>
      <p className="mt-4">
        A wizard who loves design and code. As a <b className="sp">UX & UI Engineer</b>, I design user-friendly<br/>
        apps and websites, and develop innovative tools. <b className="sp">Currently seeking an internship</b> to <br/> hone my craft.
      </p>
      <div className="button-container">
        <GradientButton />
      </div>
      
      <RecentWorks />
      <Title 
        mainTitle="SAY HI!" 
        subtitle="Reach Out Across the Mystical Realms"
      />
       <SayHi />
      
    </div>
  );
};

export default Home;
