import React from 'react';
import Title from '../components/Title.jsx'; 
import './Blog.css'; 
import article1 from '../assests/blog1.png';

const blogPosts = [
  {
    title: 'How to Master the Perfect UI Spacing',
    date: '2024-07-14',
    image: article1,
    description: 'Essential Techniques for Designers and Developers',
    link: 'https://prabhath.hashnode.dev/how-to-master-the-perfect-ui-spacing' 
  },
  
];

const Blog = () => {
  return (
    <div className="blog-container">
      <Title 
        className="title"
        mainTitle={<>MY Blog</>}
        subtitle="Read my latest articles on UX & UI "
      />
      <div className="blog-cards">
        {blogPosts.map((post, index) => (
          <a href={post.link} className="blog-card" key={index}>
            <img src={post.image} alt={post.title} className="blog-image" />
            <div className="blog-content">
              <h3 className="blog-title">{post.title}</h3>
              <p className="blog-date">{post.date}</p>
              <p className="blog-description">{post.description}</p>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Blog;
