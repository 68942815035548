// src/components/Footer.jsx
import React from 'react';
import './Footer.css'; // Import the CSS file for the footer
import footerImage from '../assests/footerbg.png'; 
import quoteImage from '../assests/quote.png'; 

const Footer = () => {
  return (
    <div className="footer">
      <img src={footerImage} alt="Footer" className="footer-image" />
       <div className="footer-content">
        <img src={quoteImage} alt="Quote" className="quote-image" />
      </div>
    </div>
  );
};

export default Footer;
