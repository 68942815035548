// Title.jsx
import React from 'react';
import './Title.css';

const Title = ({ mainTitle, subtitle }) => {
  return (
    <div className="title-container">
      <h2 className="gradient-title">{mainTitle}</h2>
      <p>{subtitle}</p>
    </div>
  );
};

export default Title;
