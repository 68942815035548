import React from 'react';
import './GradientButton.css';
import arrowIcon from '../assests/darrow.png'; // Ensure this path is correct

const GradientButton = () => {
  const smoothScroll = (target, duration) => {
    const targetPosition = target.getBoundingClientRect().top;
    const startPosition = window.pageYOffset;
    let startTime = null;

    const ease = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t + b;
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    };

    const animation = (currentTime) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = ease(timeElapsed, startPosition, targetPosition, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    requestAnimationFrame(animation);
  };

  const handleClick = () => {
    const target = document.getElementById('recent-works');
    smoothScroll(target, 2000); // 2000ms for a slower scroll
  };

  return (
    <button className="gradient-button" onClick={handleClick}>
      <img src={arrowIcon} alt="Arrow" className="arrow-icon" />
    </button>
  );
};

export default GradientButton;
