import React from 'react';
import Title from '../components/Title.jsx';
import './Contact.css'
import SayHi from '../components/SayHi';

const Contact =() => {
    return(
        <div className='Contact container mx-auto'>
             <Title 
        className="title"
        mainTitle={<>SAY HI!</>}
        subtitle="Let's Connect and Create Together..."
      />
      <div className='hi'>
      <SayHi/>
      </div>
        </div>
    )

}
    

export default Contact;