// src/components/NavBar.jsx
import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import logo from '../assests/logo.png'; // Ensure you have your logo in the assets folder
import githubIcon from '../assests/github.png'; // Social icons
import linkedinIcon from '../assests/linkedin.png';
import behanceIcon from '../assests/behance.png';
import './NavBar.css';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="navbar">
      <div className="flex items-center">
        <Link to="/">
          <img src={logo} alt="Home" />
        </Link>
      </div>
      <div className={`navbar-links ${isOpen ? 'open' : ''}`}>
        <Link to="/work">work</Link>
        <Link to="/about">about</Link>
        <Link to="/mystory" className="my-story">my story</Link>
        <Link to="/blog">blog</Link>
        <Link to="/contact">say hi!</Link>
      </div>
      <div className="navbar-social">
        <a href="https://github.com/PrabhathR-99/prabhathux" target="_blank" rel="noopener noreferrer">
          <img src={githubIcon} alt="GitHub" />
        </a>
        <a href="https://www.linkedin.com/in/prabhath-rathnayaka-01203b217/" target="_blank" rel="noopener noreferrer">
          <img src={linkedinIcon} alt="LinkedIn" />
        </a>
        <a href="https://www.behance.net/uxprabathrathnayaka" target="_blank" rel="noopener noreferrer">
          <img src={behanceIcon} alt="Behance" />
        </a>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <div className={`bar ${isOpen ? 'open' : ''}`}></div>
        <div className={`bar ${isOpen ? 'open' : ''}`}></div>
        <div className={`bar ${isOpen ? 'open' : ''}`}></div>
      </div>
    </div>
  );
};

export default NavBar;
