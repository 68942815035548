import React from 'react';
import Title from '../components/Title.jsx';
import './About.css';
import profileImage from '../assests/about.png'; 
import { Link } from 'react-router-dom';
import tech1 from '../assests/tech1.png';
import tech2 from '../assests/tech2.png';
import tech3 from '../assests/tech3.png';
import tech4 from '../assests/tech4.png';
import tech5 from '../assests/tech5.png';
import tech6 from '../assests/tech6.png';

const About = () => {
  return (
    <div className="About container mx-auto">
      <Title 
        className="title"
        mainTitle={<>ABOUT ME</>}
        subtitle="Little about me and what I do..."
      />
      <div className="about-content">
        <img src={profileImage} alt="Profile" className="profile-image" />
        <p>Hello! I'm a dedicated UX & UI Engineer, deeply passionate about crafting intuitive and engaging digital experiences. With a solid foundation in design thinking, Figma, and React, I’m on a mission to turn complex problems into elegant solutions.</p>
        <p>
          Currently, I'm a third-year undergraduate at Sabaragamuwa University of Sri Lanka, Faculty of Computing Department of Computing and Information Systems.
        </p>
        <p>
          I'm excited to connect and bring fresh perspectives to innovative projects. Right now, <b>I’m on the lookout for an internship opportunity</b> where I can contribute my skills and grow as a UX & UI Engineer or Designer. Let’s create something amazing together!
        </p>
        <div className="download-section">
          <a href="https://drive.google.com/file/d/17e7IgKW8OVfguwXc3XHcXR7vIx5DtXqX/view?usp=drive_link" className="download-button" download="Prabhath Rathnayaka - UX & UI Engineer (Intern).pdf">
            Download My CV
          </a>
        </div>
        <div className="tech-stack">
          <h2>MY TECH STACK</h2>
          <div className="tech-icons">
            <img src={tech1} alt="Tech 1" className="tech-icon" />
            <img src={tech3} alt="Tech 3" className="tech-icon" />
            <img src={tech4} alt="Tech 4" className="tech-icon" />
            <img src={tech5} alt="Tech 5" className="tech-icon" />
            <img src={tech6} alt="Tech 6" className="tech-icon" />
            <img src={tech2} alt="Tech 2" className="tech-icon" />
          </div>
        </div>
        <div className="story-link">
          <p>Want to uncover the journey that brought me here?  <Link to="/MyStory" className="gradient-link">See my story...</Link></p>
        </div>
        {/* Full-Length Download Button */}
       
      </div>
    </div>
  );
};

export default About;
