import React from 'react';
import Title from '../components/Title.jsx';
import './MyStory.css';
import Timeline from '../components/TimeLine.jsx';

const MyStory = () => {
  return (
    <div className="MyStory container mx-auto">
      <Title 
        className="title"
        mainTitle={<>MY STORY</>}
        subtitle="Explore the top 8 milestones that have shaped my journey. These key moments are the reason I am who I am today. Take a look and see how they have built my personality."
      />
      <Timeline />
    </div>
  );
};

export default MyStory;
